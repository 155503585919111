import React, { useEffect, useState} from 'react';
import '../css/body.css';
import ImportSelectComponent from'./ImportSelectComponent.js';
import ExactOnlineComponent from'./ExactOnlineComponent.js';
import ExactGlobeComponent from'./ExactGlobeComponent.js';
import TwinfieldComponent from'./TwinfieldComponent.js';
function Body() {
  const queryParams = new URLSearchParams(window.location.search);
 
  useEffect(() =>{
  }, []); // [] makes sure that this useEffect block only runs on the first render of the component

  return (
    <div className="App-body"> 
      {!queryParams.get("import") && <ImportSelectComponent />}
      {queryParams.get("import") && queryParams.get("import") == 'exactonline' && <ExactOnlineComponent/>}
      {queryParams.get("import") && queryParams.get("import") == 'exactglobe' && <ExactGlobeComponent/>}
      {queryParams.get("import") && queryParams.get("import") == 'twinfield' && <TwinfieldComponent/>}
    </div>
  );
}

export default Body;
