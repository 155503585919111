import { DataSource, DataSourceOptions } from 'typeorm'; 
import { formatDate, importTrLine,importOpeningBalance, importAccount, importPeriod, importBasic, importCustomersupplier} from './import';
import { getCookie } from './utils';
var statusMessages = [];
export async function downloadMetadata(){
  try{
    const response = await fetch('/exactglobe/getMetadata', {
      method: 'GET',
    });
    const blob = await response.blob(); // Get the response as a Blob
    const url = window.URL.createObjectURL(blob); // Create a URL for the Blob
    const a = document.createElement('a'); // Create an anchor element
    a.style.display = 'none'; // Hide the anchor
    a.href = url; // Set the href to the Blob URL
    a.download = 'metadata.xml'; // Set the default filename
    document.body.appendChild(a); // Append to body
    a.click(); // Programmatically click the anchor to trigger the download
    window.URL.revokeObjectURL(url); // Clean up

    return response;
  }
  catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function test2(){
  try{
    const response = await fetch('/exactglobe/ntlmGetData', {
      method: 'GET',
    });
    return response;
  }
  catch (error) {
    console.error('Error fetching data:', error);
  }
}

// Function to establish a connection to the database
export async function getConnection(clientConfig) {
  try{
    const response = await fetch('http://localhost:3001/api/get_connection', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: clientConfig,
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

//Submits import exact updates/changes
export async function updateImportExactGlobe(id, openingBalance, ignoreYearEnd){
  try{
    const response = await fetch('/import_exactglobe/updateImport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        openingBalance: openingBalance,
        ignoreYearEnd: ignoreYearEnd,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function createImp(entiteit, year){
  try{
    const response = await fetch('/exactglobe/createImp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entiteit: entiteit,
        year: year,
        factviewAccess: getCookie("factview_access"),
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getImportExactGlobe(entiteit, year, budgetScenario){
  try{
    const response = await fetch('/import_exactglobe/getImportExactGlobe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entiteit: entiteit,
        year: year,
        budget_scenario: budgetScenario,
        exactglobe_token: getCookie("ExactGlobeToken"),
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function updateImpid(impid, entiteit, year, budgetScenario){
  try{
    const response = await fetch('/import_exactglobe/updateImpid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        impid: impid,
        entiteit: entiteit,
        year: year,
        budget_scenario: budgetScenario,
        exactglobe_token: getCookie("ExactGlobeToken"),
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

// Delete import exact by id
export async function deleteImport(id){
  try {  
    const importResponse = await fetch('/import_exactglobe/getById?id='+id);
    if(importResponse.ok){
      const importData = await importResponse.json();

      // await clearExistingScheduledImports({"import_type": "exactonline", "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"]})
    
      const response = await fetch('/import_exactglobe/deleteImport?id='+id);
      if (response.ok) {
      const responseData = await response.json();
      
      if(responseData){
      }
    }
    
  }
  } catch (error) {
    console.error('Error deleting data:', error);
  }
}

export async function handleImport(data, updateStatus){
  statusMessages = [];
  const case_id = data["entiteit"].substring(0, data["entiteit"].indexOf('_'));
  
  var importExactGlobe = await getImportExactGlobe(data["entiteit"], data["year"], data["budget_scenario"], data["exactglobe_token"]);
  if(importExactGlobe.impid == 0){
    const imp = await createImp(data["entiteit"], data["year"]);

    importExactGlobe = await updateImpid(imp["id"], data["entiteit"], data["year"], data["budget_scenario"])
  }

  statusMessages.push({"text": "Initiating Exact Globe import!", "progress": 1, "status": 1});
  updateStatus([...statusMessages]);

  await initImport(case_id, importExactGlobe["impid"], getCookie("factview_access"));

  statusMessages.push({"text": "Fetching TrLines", "progress": 10, "status": 1});
  updateStatus([...statusMessages]);

  var trLines = await handleTrLines(case_id, data["year"], importExactGlobe["impid"], importExactGlobe["ignore_year_end"], updateStatus);
 
  await importTrLine(case_id, trLines);
  
  statusMessages.push({"text": "TrLines lines processed: " + trLines.length, "progress": 25, "status": 1});
  updateStatus([...statusMessages]);
  
  if(data["opening_balance"]){
    var obLines = await handleOpeningBalance(data["year"], importExactGlobe["impid"], data["entiteit"]);
    await importOpeningBalance(case_id, obLines);
  }
  
  statusMessages.push({"text": "Fetching Account", "progress": 30, "status": 1});
  updateStatus([...statusMessages]);
  
  var account = await handleAccount(importExactGlobe["impid"], updateStatus);
  
  await importAccount(case_id, account);
  
  statusMessages.push({"text": "Account records processed:" + account.length, "progress": 40, "status": 1});
  updateStatus([...statusMessages]);

  statusMessages.push({"text": "Fetching period", "progress": 45, "status": 1});
  updateStatus([...statusMessages]);
  
  var period = await handlePeriod(data["year"], importExactGlobe["impid"],updateStatus);
  await importPeriod(case_id, period);
 
  statusMessages.push({"text": "Period records processed:" + period.length, "progress": 60, "status": 1});
  updateStatus([...statusMessages]);

  statusMessages.push({"text": "Fetching basic", "progress": 65, "status": 1});
  updateStatus([...statusMessages]);
  
  var basic = await handleBasic(importExactGlobe["impid"], updateStatus);
  await importBasic(case_id, basic);

  statusMessages.push({"text": "Basic records processed: "+ basic.length, "progress": 75, "status": 1});
  updateStatus([...statusMessages]);
  
  statusMessages.push({"text": "Fetching customer supplier", "progress": 80, "status": 1});
  updateStatus([...statusMessages]);

  var customerSupplier = await handleCustomerSupplier(importExactGlobe["impid"], updateStatus);
  await importCustomersupplier(case_id, customerSupplier);

  statusMessages.push({"text": "Customer supplier records processed: "+ customerSupplier.length, "progress": 90, "status": 1});
  updateStatus([...statusMessages]);

  statusMessages.push({"text": "Handling procedure calculations...", "progress": 95, "status": 1});
  updateStatus([...statusMessages]);
  await handleProcedures(case_id, getCookie('factview_access'), importExactGlobe["impid"]);

  await fetch('/import_exactglobe/updateStamp?id='+data["id"]);

  statusMessages.push({"text": "Import was completed successfully!", "progress": 100, "status": 2});
  updateStatus([...statusMessages]);


  return true;
}

async function initImport(case_id, impid, factviewAccess){
  try{
    const response = await fetch('/exactglobe/initImport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        case_id: case_id,
        impid: impid,
        factviewAccess: factviewAccess,
      }),
    })
    if (response.ok) {
  
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
async function fetchTrLines(){
  try{
    const response = await fetch('http://localhost:3001/api/fetchTrLine', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseData = await response.json();
      return (responseData.success) ? responseData.data : responseData.success;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function handleTrLines(case_id, year, impid, ignoreYearEnd, updateStatus){
  var trLines = await fetchTrLines();
  var arr = [];
  statusMessages.push({"text": "TrLines lines fetched: " + trLines.length, "progress": 20, "status": 1});
  updateStatus([...statusMessages]);

  if(trLines){
    for(const trline of trLines){
      var trDate = (trline["datum"])?new Date(trline["datum"]):null;
      if(trDate && trDate.getFullYear() == year && trline["reknr"] && trline["volgnr5"]){
        var trPeriod = (trline["periode"])?trline["periode"].trim():trline["periode"];
        if(ignoreYearEnd && (trline["jrndesc"] && trline["jrndesc"].toUpperCase().indexOf("MEMOR") !== -1) && trPeriod == '12' && (trline["oms25"] && (trline["oms25"].indexOf("Result") !== -1 && trline["oms25"].indexOf("Result") === 0) || (trline["oms25"].indexOf("result") !== -1 && trline["oms25"].indexOf("result") === 0) || trline["oms25"].indexOf("Verwerking resultaat"))){
        
        }
        else{
          var dateString = await formatDate(trDate);
          var custsupid = (trline["debnr"])?trline["debnr"]:trline["crdnr"];
          var object = {
            "impid": impid,
            "nr": (trline["volgnr5"])?trline["volgnr5"].trim():trline["volgnr5"],
            "accid": (trline["reknr"])?trline["reknr"].trim():trline["reknr"],
            "custsupid": (custsupid)?custsupid.trim():custsupid,
            "amnt": trline["bdr_val"],
            "type": "d",
            "costid": (trline["kstplcode"])?trline["kstplcode"].trim():trline["kstplcode"],
            "productid": trline["artcode"],
            "jrndesc": trline["jrndesc"],
            "jrnid": trline["dagbknr"],
            "trdesc": trline["trdesc"],
            "trnr": trline["TransactionNumber"],
            "trperiod": trPeriod,
            "trdate": dateString,
            "linedesc": trline["oms25"],
            "docnr": trline["docnumber"], 
            "entiteit":case_id+"_1",
            "_custsupdesc":trline["cmp_name"],
            "_productdesc":trline["Description"]
          }
          arr.push(object);
        }
        
      }
    }
    return arr;
  }
  else {
    return false
  }
    
}

async function fetchAccount(){
  try{
    const response = await fetch('http://localhost:3001/api/fetchAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseData = await response.json();

      return (responseData.success) ? responseData.data : responseData.success;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function handleAccount(impid, updateStatus){
  var accounts = await fetchAccount();
  statusMessages.push({"text": "Account records fetched:" + accounts.length, "progress": 35, "status": 1});
  updateStatus([...statusMessages]);
  if(accounts){
    var arr = [];

    for(const account of accounts){
      var object = {
        "impid": impid,
        "accid": (account["reknr"])?account["reknr"].trim():account["reknr"],
        "name": account["oms25_0"],
        "type": (account["bal_vw"] == "W") ? "PNL" : "BAL",
      }
      arr.push(object);
    }
    return arr;
  }
  else{
    return false;
  }
}

async function fetchPeriod(year){
  try{
    const response = await fetch('http://localhost:3001/api/fetchPeriod?year='+year, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseData = await response.json();
     
      return (responseData.success) ? responseData.data : responseData.success;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function handlePeriod(year, impid, updateStatus){
  var periods = await fetchPeriod(year);
  statusMessages.push({"text": "Period records fetched:" + periods.length, "progress": 50, "status": 1});
  updateStatus([...statusMessages]);
  var arr = [];
  if(periods){
    for(const period of periods){
      var object = {
        "impid": impid,
        "number": (period["per_fin"])?parseInt(period["per_fin"].trim()):parseInt(period["per_fin"]),
        "desc": (period["per_fin"])?"Periode "+period["per_fin"].trim():"Periode "+period["per_fin"],
        "startDate": new Date(period["bgdatum"]),
        "endDate": new Date(period["eddatum"]),
      }
      arr.push(object);
    }
    return arr;
  }
  else{
    return false;
  }
  
}


async function fetchCostCenter(){
  try{
    const response = await fetch('http://localhost:3001/api/fetchCostCenter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseData = await response.json();
      
      return (responseData.success) ? responseData.data : responseData.success;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function fetchCostUnit(){
  try{
    const response = await fetch('http://localhost:3001/api/fetchCostUnit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseData = await response.json();

      return (responseData.success) ? responseData.data : responseData.success;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function handleBasic(impid, updateStatus){
  var costCenters = await fetchCostCenter();
  var costUnits = await fetchCostUnit();
  
  statusMessages.push({"text": "Basic records fetched:" + (costCenters.length + costUnits.length), "progress": 70, "status": 1});
  updateStatus([...statusMessages]);
  
  var arr = [];
  if(costCenters && costUnits){
    for(const cc of costCenters){
      var object = {
        "impid": impid,
        "id": (cc["kstplcode"])?cc["kstplcode"].trim():cc["kstplcode"],
        "desc": cc["oms25_0"],
        "type": "02",
      }
      if(object.id){
        arr.push(object);
      }
      
    }

    for(const cu of costUnits){
      var object = {
        "impid": impid,
        "id": (cu["kstdrcode"])?cu["kstdrcode"].trim():cu["kstdrcode"],
        "desc": cu["oms25_0"],
        "type": "01",
      }
      if(object.id){
        arr.push(object);
      }
    }
    return arr;
  }
  else{
    return false;
  }
}

async function fetchCustomerSupplier(){
  try{
    const response = await fetch('http://localhost:3001/api/fetchCustomerSupplier', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseData = await response.json();
      
      return (responseData.success) ? responseData.data : responseData.success;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function handleCustomerSupplier(impid, updateStatus){
  var customerSupplier = await fetchCustomerSupplier();

  statusMessages.push({"text": "Customer supplier records fetched:" + customerSupplier.length, "progress": 80, "status": 1});
  updateStatus([...statusMessages]);
  if(customerSupplier){
    var arr = [];

    for(const cs of customerSupplier){
      var object = {
        "impid": impid,
        "id": cs["id"].toString(),
        "name": cs["cmp_name"],
        "postalcode": cs["cmp_fpc"],
        "city": cs["cmp_fcity"],
        "country": (cs["cmp_fctry"])?cs["cmp_fctry"].trim():cs["cmp_fctry"],
        "entiteit":"",
      }

      arr.push(object);
    }
    return arr;
  }
  else{
    return false;
  }  
}

async function fetchOpeningBalance(year){
  try{
    const response = await fetch('http://localhost:3001/api/fetchOpeningBalance?year='+year, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseData = await response.json();
     
      return (responseData.success) ? responseData.data : responseData.success;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function handleOpeningBalance(year, impid, entiteit, updateStatus){
  var openingBalance = await fetchOpeningBalance(year - 1);
  if(openingBalance){
    var arr = [];
    for(const ob of openingBalance){
      var object = {
        "accid": (ob["GeneralLedger"])?ob["GeneralLedger"].trim():ob["GeneralLedger"],
        "impid": impid,
        "amnt": ob["AmountDC"],
        "type": "d",//(ob["AmountDC"] < 0)?"c":"d",
        "source": "exactglobe",
        "entiteit": entiteit,
      }
  
      arr.push(object);
    }
    return arr;
  }
  else{
    return false;
  }  
}

async function handleProcedures(case_id, factviewAccess, impid){
  try{
    const response = await fetch('/exactglobe/handleProcedure', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        case_id: case_id,
        factview_access: factviewAccess,
        impid, impid,
      }),
    })
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
// export async function 
export async function test(clientConfig){
  const { host, port, database, useWindowsAuth, username, password } = clientConfig;
  
  
  const dataSourceOptions = {
    name: 'mssql',
    type: 'mssql',
    host: host || 'localhost',
    port: port || 1433,
    database: database,
    entities: [
    ],
    synchronize: false, // Set to true if you want to sync your database schema
    options: {
      encrypt: false, // Change to true if you are using SSL
      trustServerCertificate: true, // Allow self-signed certificates
      ...(useWindowsAuth ? { trustedConnection: true } : {}), // Add this line for Windows Authentication
    },
    ...(useWindowsAuth ? {} : { // Set username and password only if not using Windows Authentication
      username: username, // Replace with your SQL Server username
      password: password, // Replace with your SQL Server password
  }),
  };
  try {
      const dataSource = new DataSource(dataSourceOptions);
      await dataSource.initialize();

      const result = await dataSource.query('SELECT 1');
  } catch (error) {
      console.error('SQL error', error);
  }
}



