import React, { useEffect, useState } from 'react';
import '../css/ImportComponent.css';
import { useTranslations  } from './TranslationContext';

// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faChevronDown, faArrowLeft, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Add the icons you will use to the library
library.add(faChevronDown, faXmark, faArrowCircleLeft);

const ImportHeaderComponent = ({title, logo, subtitle}) => {
  const { getTranslation } = useTranslations();
  useEffect(() =>{
  },[]);

  // Redirect to home page event
  async function handleBackClicked(){
    window.location.href = '/app'
  }

  return (
    <div className="import-header-component">
      <div className="back-btn-container">
        <FontAwesomeIcon className="btn-back hover" onClick={handleBackClicked} icon={faArrowCircleLeft}/>
      </div>
      <div className="import-header">
        <div className="import-header-title">
          <p>{title}</p>
          <div className="component-logo import-header-logo">           
            <img src={logo} className="import-logo" alt="logo" />
          </div>
        </div>
      </div>
      <div className="import-header-subtitle">
        <p>{subtitle}</p>
      </div>    
    </div>
  );
}

export default ImportHeaderComponent;
