import { getConnection } from './exactglobe';
import { checkImportExact, clearExistingScheduledImports} from './import';
import { getCookie } from './utils';
// Handles exactonline import execution on the server
export async function handleImport(row, updateStatus){
  // await checkImportExact(data[id].entiteit, data[id].year, data[id].budget_scenario, office);
  try {
    handleStatus(updateStatus);
    const response = await fetch('/twinfield/executeImport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Connection': 'keep-alive',
      },
      body: JSON.stringify({
        entiteit: row.entiteit,
        year: row.year,
        budget_scenario: row.budget_scenario,
        office: row.office,
      }),
    });
    if (response.ok) {
      // const responseData = await response.json();  
      // return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    handleStatus({"text": "Error!", "progress": 1, "status": 0});
  }
}

//Submits import exact updates/changes
export async function updateImportTwinfield(id, openingBalance, ignoreYearEnd){
  try{
    const response = await fetch('/import_twinfield/updateImportTwinfield', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        openingBalance: openingBalance,
        ignoreYearEnd: ignoreYearEnd,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

// Delete import exact by id
export async function deleteImportTwinfield(id){
  try { 
    const importResponse = await fetch('/import_twinfield/getById?id='+id);
    if(importResponse.ok){
      const importData = importResponse.json();

      await clearExistingScheduledImports({"import_type": "twinfield", "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"]})
    
      const response = await fetch('/import_twinfield/deleteImportTwinfield?id='+id);
      if (response.ok) {
        const responseData = await response.json();
      if(responseData){
      }
    }
  }
  } catch (error) {
    console.error('Error deleting data:', error);
  }
}

export async function updateTwinfieldTokens(twinfieldUser){
  const response = await fetch('/import_twinfield/updateTokens?twinfield_user='+twinfieldUser+'&twinfield_access='+getCookie("twinfield_access")+"&twinfield_refresh="+getCookie("twinfield_refresh")+'&factview_access='+getCookie("factview_access")+"&factview_refresh="+getCookie("factview_refresh"));
  if(response.ok){

  }
}
// Handles fetching status updates from the server
async function handleStatus(updateStatus){
  try{
    const response = await fetch('/twinfield/fetchUpdates');
    if (response.ok) {
        const responseData = await response.json();
        
        if(responseData){
          updateStatus(responseData);

          if(responseData.length > 0 && responseData[responseData.length-1]["status"] == 1){
            setTimeout(function(){
              handleStatus(updateStatus);
            },500);
          }
        } 
      }
  } catch(error) {
    setTimeout(function(){
      handleStatus(updateStatus);
    },500);
  }
  
}


