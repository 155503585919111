import React, { useEffect, useState } from 'react';
import { getCookie, clearQueryParams, getCurrentUser, getExpireDate, deleteCookie } from '../utilities/utils'; // Import the utility function
import { getConnection, test } from '../utilities/exactglobe'; // Import the utility function
import CryptoJS from 'crypto-js';
import '../css/ImportComponent.css';
import '../css/ExactGlobeComponent.css';
import { useTranslations  } from './TranslationContext';

const OnPremiseAuthComponent = ({authenticate, setSelectedUser}) => {
  const [database, setDatabase] = useState("");
  const [host, setHost] = useState("localhost");
  const [port, setPort] = useState(1433);
  const [windowsAuth, setWindowsAuth] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() =>{
  },[database, host, port, windowsAuth, username, password]);
  
  async function exactGlobeClientAuth(){
    var connected = await getConnection(JSON.stringify({
      host: host,
      port: port,
      database: database,
      useWindowsAuth: windowsAuth,
      username: username,
      password: password
    }));
    
    setSelectedUser((windowsAuth)?"Windows Auth":username);

    if(connected){
      document.cookie = `ExactGlobeToken=${await getHashedDbSession(host+port+database)};path=/`;
      document.cookie = `ExactGlobeClientId=${await getClientId()};path=/`;
      
      authenticate(connected);
    }
    else{
      deleteCookie("ExactGlobeToken");
    }
  }
  
  async function exactGlobeServerAuth(){
    const response = await fetch('/exactglobe/connect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        host: host,
        port: port,
        database: database,
        useWindowsAuth: windowsAuth,
        username: username,
        password: password
      }),
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
      authenticate(responseData);
    } 
  }

  function handleInputOnChange(event){
    var element = event.target;

    if(element.id == "input-database"){
      setDatabase(element.value);
    }
    else if(element.id == "input-host"){
      setHost(element.value);
    }
    else if(element.id == "input-port"){
      setPort(element.value);
    }
    else if(element.id == "input-username"){
      setUsername(element.value);
    }
    else if(element.id == "input-password"){
      setPassword(element.value);
    }
  }
  async function getHashedDbSession(string){
    const hash = CryptoJS.MD5(string).toString(CryptoJS.enc.Hex);
    console.log(hash);
    return hash;
  }
  async function getClientId(){
    const response = await fetch('/app_user/getClientId?userId='+await getCurrentUser());
    if(response.ok){
      const responseData = await response.json();
      console.log(responseData.clientId);
      return responseData.clientId;
    }
  }
  function handleDropDown(event){
    const value = event.target.getAttribute("value") === "true";
    if(value){
      setUsername("");
      setPassword("");
    }
    setWindowsAuth(value);
  }

  return (
    <div className="exactglobe-authform">
      <div className="authform-header">
        <p>Server</p>
        <div className="vertical-line"></div>
      </div>
      <div className="authform-body">
      <div className="authform-config authform-database">
          <div className="authform-inputlabel">
            <p>Database:</p>
          </div>
          <div className="authform-inputcontainer">
            <input id="input-database" value={database} onChange={handleInputOnChange} placeholder="Database name"/>
          </div>
        </div>
        <div className="authform-config authform-host">
          <div className="authform-inputlabel">
            <p>Host:</p>
          </div>
          <div className="authform-inputcontainer">
            <input id="input-host" value={host} onChange={handleInputOnChange} placeholder="localhost"/>
          </div>
        </div>
        <div className="authform-config authform-port">
          <div className="authform-inputlabel">
            <p>Port:</p>
          </div>
          <div className="authform-inputcontainer">
            <input id="input-port" type="number" value={port} placeholder="1433" onChange={handleInputOnChange}/>
          </div>
        </div>
        <div className="authform-config authform-port">
          <div className="authform-inputlabel">
            <p>Authentication:</p>
          </div>
          <div className="authform-inputcontainer">
            <div className="dropdown">
              <div className="dropdown-input" value={windowsAuth}>{(windowsAuth)?"Windows Authentication": "SQL Server Authentication"}</div>
              <div className="dropdown-content">
                  <a onClick={handleDropDown} value={true} href="#">Windows Authentication</a>
                  <a onClick={handleDropDown} value={false} href="#">SQL Server Authentication</a>
              </div>
            </div>
          </div> 
        </div>
        <div className={`authform-config authform-username ${(windowsAuth)?"locked":""}`}>
          <div className="authform-inputlabel">
            <p>Username:</p>
          </div>
          <div className="authform-inputcontainer">
            <input id="input-username" value={username} placeholder="" disabled={windowsAuth} onChange={handleInputOnChange}/>
          </div>
        </div>
        <div className={`authform-config authform-password ${(windowsAuth)?"locked":""}`}>
          <div className="authform-inputlabel">
            <p>password:</p>
          </div>
          <div className="authform-inputcontainer">
            <input id="input-password" value={password} placeholder="" disabled={windowsAuth} onChange={handleInputOnChange}/>
          </div>
        </div>
      </div>
      <div className="authform-footer">
        <button className="import-btn hover" onClick={exactGlobeClientAuth}>Connect</button>
      </div>
    </div>
  );
}

export default OnPremiseAuthComponent;
